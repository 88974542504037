import request from '@/utils/request'

//批量更新测评参数
export function batchCreateTestOrderParam(data) {
  return request({
    url: '/erp/test-order-param/batch-create',
    method: 'post',
    data: data
  })
}

// 创建测评参数
export function createTestOrderParam(data) {
  return request({
    url: '/erp/test-order-param/create',
    method: 'post',
    data: data
  })
}

// 更新测评参数
export function updateTestOrderParam(data) {
  return request({
    url: '/erp/test-order-param/update',
    method: 'put',
    data: data
  })
}

// 删除测评参数
export function deleteTestOrderParam(id) {
  return request({
    url: '/erp/test-order-param/delete?id=' + id,
    method: 'delete'
  })
}

// 获得测评参数
export function getTestOrderParam(id) {
  return request({
    url: '/erp/test-order-param/get?id=' + id,
    method: 'get'
  })
}

// 获得测评参数分页
export function getTestOrderParamPage(query) {
  return request({
    url: '/erp/test-order-param/page',
    method: 'get',
    params: query
  })
}

// 导出测评参数 Excel
export function exportTestOrderParamExcel(query) {
  return request({
    url: '/erp/test-order-param/export-excel',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

// 获得币种
export function getCurrency(params) {
  return request({
    url: '/erp/test-order-param/get-currency',
    method: 'get',
    params
  })
}
// 删除测评参数
export function batchDelete(params) {
  return request({
    url: '/erp/test-order-param/batch-delete',
    method: 'delete',
    params
  })
}
