<template>
  <div :class="{'has-logo':showLogo}" :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <div class="sidebar-left">
      <div class="logo-line">
        <logo />
        <!-- <hamburger id="hamburger-container" :class="['hamburger-container',sidebar.opened||'small']" @toggleClick="toggleSideBar" /> -->
      </div>
      <div class="erp-box">
        <div :class="['change-btn bi',isBi==='bi'&&'active']" @click="cutBi('bi')">BI</div>
        <div :class="['change-btn erp',isBi==='erp'&&'active']" @click="cutBi('erp')">ERP</div>
        <!-- <div :class="['change-btn ai',isBi==='ai'&&'active']" @click="cutBi('ai')">AI</div> -->
        <div v-if="!forChatplus()" :class="['change-btn crm',isBi==='crm'&&'active']" style="padding: 19px 4px 3px" @click="cutBi('crm')">CRM</div>
      </div>
      <div class="bottom-exp" :class="{left:sidebar.opened}" @click="toggleSideBar"></div>
      <div class="bottom-btns">
        <el-menu
          style="height:45px"
          :class="[show&&'show-child']"
          :default-active="activeMenu"
          :collapse="true"
          :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
          :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
          :unique-opened="true"
          active-text-color="#1377FF"
          :collapse-transition="false"
          menu-trigger="click"
          mode="horizontal"
        >
          <sidebar-item-set
            v-for="(route, index) in sidebarRouters.filter(route=>route.path==='/system')"
            :key="route.path  + index"
            :item="route"
            :base-path="route.path"
            @changeSub="changeSub"
            @changeShow="changeShow"
          />
        </el-menu>
      </div>
    </div>
    <div class="sidebar-fixed-menu">
      <div class="left-menu">
        <div class="flex-row flex-between align-center" style="margin-bottom: 30px">
          <div class="fixed-logo" :class="forChatplus() ? 'chat' : isBi">
          </div>
          <div class="pro"></div>
        </div>

        <el-scrollbar :class="[settings.sideTheme,sidebar.opened||'small']" wrap-class="scrollbar-wrapper">
          <el-menu
            ref="sidebarMenu"
            :default-active="activeMenu"
            :collapse="false"
            :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
            :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
            :unique-opened="true"
            active-text-color="#1377FF"
            :collapse-transition="false"
            mode="vertical"
          >
            <sidebar-item
              v-for="(route, index) in menuRouters.concat(normalRouters)"
              :key="route.path  + index"
              :item="route"
              :base-path="route.path"
            />
          </el-menu>
        </el-scrollbar>
      </div>
    </div>
    <div v-if="show" class="sidebar-fixed-sub">
      <div class="layout" @click="show=false"></div>
      <div class="left-menu">
        <div class="navs">
          <div class="nav" v-for="(item, index) in changeSubs">
            <div class="group-tit" v-if="index===0||item.groupName!==changeSubs[index-1].groupName">{{ item.groupName ? $t(`menu.${item.groupName}`) : '' }}</div>
            <router-link :to="item.resolvePath" v-if="item.visible">
              <div class="sub-item" :class="{active:$route.fullPath===item.resolvePath}" @click="show=false">
                <item v-if="item.meta" :title="item.meta.title" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import path from 'path'
import Item from './Item'
import {getPath} from "@/utils/ruoyi";
import { isExternal } from '@/utils/validate'
import Hamburger from '@/components/Hamburger'
import SidebarItem from "./SidebarItem";
import SidebarItemSet from "./SidebarItemSet";
import variables from "@/assets/styles/variables.scss";
import { watch } from "fs";
export default {
  components: { SidebarItem,SidebarItemSet,Hamburger, Logo,Item },
  data(){
    return {
      changeSubs:[],
      show:false,
      isBi:'bi',
      aiRouters:[],
      biRouters:[],
      erpRouters:[],
      crmRouters: [],
      menuRouters:[],
      normalRouters:[]
    }
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar","routeMenuPriority",'isShopAdAuth']),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  watch:{
    '$route.path'(val){
      if(val==='/bi/com-analyse/bigBoard'&&this.sidebar.opened){
        this.$store.dispatch('app/toggleSideBar')
      }
    }
  },
  mounted() {
    const {sidebarRouters,routeMenuPriority}=this;
    let notSys=sidebarRouters.filter(item=>item.path!=='/system');
    let biRouters=notSys.filter(item=>item.sidebarType==='bi');
    let aiRouters=notSys.filter(item=>item.sidebarType==='ai');
    let erpRouters=notSys.filter(item=>item.sidebarType==='erp');
    let crmRouters=notSys.filter(item=>item.sidebarType==='crm');
    let normalRouters=notSys.filter(item=>item.sidebarType==='normal');
    if(biRouters.length===0&&erpRouters.length>0){
      this.isBi='erp'
    }
    if(routeMenuPriority==='bi'){
      this.isBi='bi';
      this.menuRouters=biRouters
    }else if(routeMenuPriority==='ai'){
      this.isBi='ai';
      this.menuRouters=aiRouters
    }else if(routeMenuPriority==='crm'){
      this.isBi='crm';
      this.menuRouters=crmRouters
    }else{
      this.isBi='erp';
      this.menuRouters=erpRouters
    }
    this.aiRouters=aiRouters;
    this.biRouters=biRouters;
    this.erpRouters=erpRouters;
    this.crmRouters=crmRouters;
    this.normalRouters=normalRouters;
    this.$nextTick(()=>{
      if(this.$store.state.permission.currentMenu === 'crm' || this.$store.state.permission.currentMenu === 'bi' || this.$store.state.permission.currentMenu === 'ai' || this.$store.state.permission.currentMenu === 'erp'){
        this.cutBi(this.$store.state.permission.currentMenu)
      }
    })
  },
  methods:{
    cutBi(type){
      const {biRouters,aiRouters,erpRouters,normalRouters, crmRouters}=this;
      let isRedirect=false;
      if(type==='bi'){
        isRedirect=biRouters.length===0;
        this.menuRouters=biRouters
      }else if(type==='erp'){
        isRedirect=erpRouters.length===0;
        this.menuRouters=erpRouters
      }else if(type==='crm'){
        isRedirect=crmRouters.length===0;
        this.menuRouters=crmRouters
      }else{
        // this.isBi=type;
        // this.$router.push('/index/noaccess')
        // return;
        isRedirect=aiRouters.length===0;
        this.menuRouters=aiRouters
      }
      this.isBi=type;
      if(isRedirect){
        this.$router.push('/index/noaccess')
        return;
      }
      if(!this.sidebar.opened){
        this.$store.dispatch('app/toggleSideBar')
      }
      this.show=false;
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    toggleSideBar() {
      this.show=false;
      this.$store.dispatch('app/toggleSideBar')
    },
    close(path,index){
      this.$refs.sidebarMenu.close(path)
    },
    changeShow(bool){
      this.show=bool;
    },
    changeSub(children){
      const currency = localStorage.getItem('currency')
      if (currency && currency !== 'CNY') {
        children = children.length && children.filter(child=>child.resolvePath != "/system/ex-rate")
      }
      this.changeSubs=children
    }
  }
};
</script>
