var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.variables.menuBackground
            : _vm.variables.menuLightBackground,
      },
    },
    [
      _c("div", { staticClass: "sidebar-left" }, [
        _c("div", { staticClass: "logo-line" }, [_c("logo")], 1),
        _c("div", { staticClass: "erp-box" }, [
          _c(
            "div",
            {
              class: ["change-btn bi", _vm.isBi === "bi" && "active"],
              on: {
                click: function ($event) {
                  return _vm.cutBi("bi")
                },
              },
            },
            [_vm._v("BI")]
          ),
          !_vm.forChatplus()
            ? _c(
                "div",
                {
                  class: ["change-btn erp", _vm.isBi === "erp" && "active"],
                  on: {
                    click: function ($event) {
                      return _vm.cutBi("erp")
                    },
                  },
                },
                [_vm._v("ERP")]
              )
            : _vm._e(),
          !_vm.forChatplus()
            ? _c(
                "div",
                {
                  class: ["change-btn crm", _vm.isBi === "crm" && "active"],
                  staticStyle: { padding: "19px 4px 3px" },
                  on: {
                    click: function ($event) {
                      return _vm.cutBi("crm")
                    },
                  },
                },
                [_vm._v("CRM")]
              )
            : _vm._e(),
        ]),
        _c("div", {
          staticClass: "bottom-exp",
          class: { left: _vm.sidebar.opened },
          on: { click: _vm.toggleSideBar },
        }),
        _c(
          "div",
          { staticClass: "bottom-btns" },
          [
            _c(
              "el-menu",
              {
                class: [_vm.show && "show-child"],
                staticStyle: { height: "45px" },
                attrs: {
                  "default-active": _vm.activeMenu,
                  collapse: true,
                  "background-color":
                    _vm.settings.sideTheme === "theme-dark"
                      ? _vm.variables.menuBackground
                      : _vm.variables.menuLightBackground,
                  "text-color":
                    _vm.settings.sideTheme === "theme-dark"
                      ? _vm.variables.menuColor
                      : _vm.variables.menuLightColor,
                  "unique-opened": true,
                  "active-text-color": "#1377FF",
                  "collapse-transition": false,
                  "menu-trigger": "click",
                  mode: "horizontal",
                },
              },
              _vm._l(
                _vm.sidebarRouters.filter(function (route) {
                  return route.path === "/system"
                }),
                function (route, index) {
                  return _c("sidebar-item-set", {
                    key: route.path + index,
                    attrs: { item: route, "base-path": route.path },
                    on: {
                      changeSub: _vm.changeSub,
                      changeShow: _vm.changeShow,
                    },
                  })
                }
              ),
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "sidebar-fixed-menu" }, [
        _c(
          "div",
          { staticClass: "left-menu" },
          [
            _c(
              "div",
              {
                staticClass: "flex-row flex-between align-center",
                staticStyle: { "margin-bottom": "30px" },
              },
              [
                _c("div", {
                  staticClass: "fixed-logo",
                  class: _vm.forChatplus() ? "chat" : _vm.isBi,
                }),
                _c("div", { staticClass: "pro" }),
              ]
            ),
            _c(
              "el-scrollbar",
              {
                class: [_vm.settings.sideTheme, _vm.sidebar.opened || "small"],
                attrs: { "wrap-class": "scrollbar-wrapper" },
              },
              [
                _c(
                  "el-menu",
                  {
                    ref: "sidebarMenu",
                    attrs: {
                      "default-active": _vm.activeMenu,
                      collapse: false,
                      "background-color":
                        _vm.settings.sideTheme === "theme-dark"
                          ? _vm.variables.menuBackground
                          : _vm.variables.menuLightBackground,
                      "text-color":
                        _vm.settings.sideTheme === "theme-dark"
                          ? _vm.variables.menuColor
                          : _vm.variables.menuLightColor,
                      "unique-opened": true,
                      "active-text-color": "#1377FF",
                      "collapse-transition": false,
                      mode: "vertical",
                    },
                  },
                  _vm._l(
                    _vm.menuRouters.concat(_vm.normalRouters),
                    function (route, index) {
                      return _c("sidebar-item", {
                        key: route.path + index,
                        attrs: { item: route, "base-path": route.path },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.show
        ? _c("div", { staticClass: "sidebar-fixed-sub" }, [
            _c("div", {
              staticClass: "layout",
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            }),
            _c("div", { staticClass: "left-menu" }, [
              _c(
                "div",
                { staticClass: "navs" },
                _vm._l(_vm.changeSubs, function (item, index) {
                  return _c(
                    "div",
                    { staticClass: "nav" },
                    [
                      index === 0 ||
                      item.groupName !== _vm.changeSubs[index - 1].groupName
                        ? _c("div", { staticClass: "group-tit" }, [
                            _vm._v(
                              _vm._s(
                                item.groupName
                                  ? _vm.$t("menu." + item.groupName)
                                  : ""
                              )
                            ),
                          ])
                        : _vm._e(),
                      item.visible
                        ? _c(
                            "router-link",
                            { attrs: { to: item.resolvePath } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "sub-item",
                                  class: {
                                    active:
                                      _vm.$route.fullPath === item.resolvePath,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.show = false
                                    },
                                  },
                                },
                                [
                                  item.meta
                                    ? _c("item", {
                                        attrs: { title: item.meta.title },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }