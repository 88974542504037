var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "app-main",
        (_vm.key === "/index/home" || ~_vm.key.indexOf("/data/plate")) &&
          "home",
        (_vm.key === "/user/profile" ||
          _vm.key === "/user/package" ||
          _vm.key === "/product-data/remark-report" ||
          _vm.key === "/data/data/data-chart" ||
          _vm.key === "/order/shop-order" ||
          _vm.key === "/shop-data/shop-health" ||
          _vm.key === "/shop-data/shop-cycle" ||
          _vm.key === "/shop-data/disk-face" ||
          _vm.key === "/order-data/disk-face" ||
          ~_vm.key.indexOf("/shop-sale/detail") ||
          ~_vm.key.indexOf("bi/profit/detai") ||
          _vm.key === "/shop-data/user-analyze" ||
          _vm.key === "/goods/create" ||
          _vm.key === "/index/thali" ||
          _vm.key === "/index/ad" ||
          _vm.key === "/shop-data/report" ||
          _vm.key === "/finance/operator" ||
          _vm.key === "/finance/report" ||
          _vm.key === "/system/profit-set" ||
          _vm.key === "/crm/order/list" ||
          _vm.key === "/crm/cooperation-detail" ||
          _vm.key === "/performance/index" ||
          _vm.key === "/dataView/index" ||
          _vm.key === "/ad/ad-overview" ||
          ~_vm.key.indexOf("ad/shop-detail") ||
          ~_vm.key.indexOf("user-info") ||
          _vm.key === "/finance/offline-out") &&
          "height-auto",
        _vm.key === "/finance/shop-order" && "app-main-scroll",
      ],
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }