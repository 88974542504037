import { getSaleAmountPolicy } from '@/api/system/user.js'
export default {
  state: {
    notice: 0,
    explain: `1、当前销售额取值是：商品单价x数量-卖家优惠 <br/> 2、修改销售额的取值可在【设置】-【财务设置】里编辑取值方式`
  },
  mutations: {
    setNotice (state) {
      state.notice++
    },
    setExplain (state, explain) {
      state.explain = `1、当前销售额取值是：${explain}<br/> 2、修改销售额的取值可在【设置】-【财务设置】里编辑取值方式`
    }
  },
  actions:{
    async initExplain({commit}){
      const mapData = {
        0:'买家实际支付的金额',
        1:'商品单价x数量',
        2:'商品单价x数量-卖家优惠',
        3:'收到的平台回款金额，未回款则取买家实付金额',
        4:'买家实付金额+平台补贴+运费补贴'
      }
      const { data } = await getSaleAmountPolicy()
      commit('setExplain', mapData[data])
    }
  }
}