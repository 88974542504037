<template>
  <my-dialog v-model="visible" :title="$t('title11006')" width="860px">
    <div class="flex-row">
      <div class="select-view">
        <div class="warning">{{ $t('title11007') }}</div>
        <el-checkbox style="margin-bottom: 20px" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="selectArr">
          <el-col :span="8" v-for="item in selectOption" :key="item.code" style="padding: 0;margin-bottom: 20px">
            <el-checkbox :disabled="item.disabled" :label="item.code" @change="(val) => selectItem(item, val)">{{item.name}}</el-checkbox>
          </el-col>
        </el-checkbox-group>
      </div>
      <div class="sort_view_outside">
        <div class="sort_view flex-col" ref="sort_view">
          <div style="margin-bottom: 16px; color: #999">{{$t('title11008')}}</div>
          <div v-for="item in fixedArr" :key='`fixeditem_${item.code}`' class="sortItem flex-row jc-between">
            <div class="flex-row align-center">
              <img src="@/assets/images/sortIcon.png" style="width: 16px; height: 16px; margin: 0 10px" >
              <span>{{ item.name }}</span>
            </div>
            <div class="flex-row align-center">
              <img src="@/assets/images/sort_fixed.png" class="sortIcon" @click="unfixed(item.code)" >
            </div>
          </div>
          <div v-for="(item, index) in sortOption" :key='`item_${item.code}`' class="sortItem flex-row jc-between draggable">
            <div class="flex-row align-center">
              <img src="@/assets/images/sortIcon.png" style="width: 16px; height: 16px; margin: 0 10px" >
              <span>{{ item.name }}</span>
            </div>
            <div class="flex-row align-center">
              <img v-if="!item.disabled" src="@/assets/images/sort_delete.png" class="sortIcon" @click="deleteItem(item.code)" >
              <img src="@/assets/images/sort_beTop.png" class="sortIcon" @click="rise(item.code, index)" >
              <img src="@/assets/images/sort_beFixed.png" class="sortIcon" @click="beFixed(item.code)" >
            </div>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <el-button size="mini" @click="cancel">{{ $t('title587') }}</el-button>
      <el-button size="mini" type="primary" @click="submit">{{ $t('title1013') }}</el-button>
    </template>
  </my-dialog>
</template>
<script>
import Sortable from 'sortablejs'
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sortItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sortObj: null,
      dataItems: [],
      checkAll: true,
      isIndeterminate: false,
      selectArr: [],
      sortOption: [],
      fixedArr: []
    }
  },
  created() {
    const arr = [...this.sortItems]
    this.dataItems = arr.sort((a, b) => a.sort - b.sort)
    this.selectArr = this.dataItems.filter(i => i.checked).map(i => i.code)
    this.fixedArr = this.dataItems.filter(i => i.isFixed)
    if (this.selectArr.length === 0) {
      this.selectArr = this.dataItems.filter(i => !i.noPicked).map(i => i.code)
    }
    let checkedCount = this.selectArr.length
    this.checkAll = checkedCount === this.selectOption.length
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.selectOption.length
  },
  mounted() {
    setTimeout(() => {
      this.setSortOption()
      this.getSortable()
    }, 300)
  },
  computed: {
    selectOption() {
      const origin = [...this.sortItems]
      const arr = Array.from([...origin.filter(item=>!item.noMove)])
      return arr
    },
  },
  methods: {
    getSortable () {
      const _dom = document.querySelector('.sort_view')
      const _this = this
      this.sortObj = Sortable.create(_dom, {
        draggable: '.draggable',
        // 拖拽结束
        onEnd ({oldDraggableIndex, newDraggableIndex}) {
          if (oldDraggableIndex !== newDraggableIndex) {
            const currRow = _this.sortOption.splice(oldDraggableIndex, 1)[0]
            _this.sortOption.splice(newDraggableIndex, 0, currRow)
          }
        }
      })
    },
    setSortOption() {
      if (this.selectArr.length > 0) {
        let arr = this.selectArr.filter(i => !this.fixedArr.some(item => item.code === i))
        // this.sortOption = arr.map(i => this.selectOption.find(item => item.code === i))
        this.sortOption = this.selectOption.filter(item => {
          return arr.includes(item.code)
        })
        this.sortOption = this.sortOption.sort((a, b) => a.sort - b.sort)
      }
    },
    handleCheckAllChange(val) {
      if(val){
        this.selectArr=this.selectOption.map(i => i.code);
        this.setSortOption()
      }else{
        this.selectArr = this.selectOption.filter(i => i.disabled).map(i => i.code)
        this.sortOption = this.sortOption.filter(i => i.disabled)
        this.fixedArr = this.fixedArr.filter(i => i.disabled)
      }
      let checkedCount = this.selectArr.length
      this.checkAll = checkedCount === this.selectOption.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.selectOption.length
    },
    selectItem(item, val) {
      if (!val) {
        const target = this.fixedArr.find(i => i.code === item.code)
        if (target) {
          this.fixedArr = this.fixedArr.filter(i => i.code !== target.code)
        }
      }
      let checkedCount = this.selectArr.length
      this.checkAll = checkedCount === this.selectOption.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.selectOption.length
      this.setSortOption()
    },
    deleteItem(code) {
      this.selectArr = this.selectArr.filter(i => i !== code)
      this.setSortOption()
    },
    rise(code, index) {
      if (index === 0) return
      let arr=this.sortOption;
      const target = arr[index];
      arr[index] = arr[index-1];
      arr[index-1] = target ;
      this.sortOption=[...arr]
    },
    beFixed(code) {
      if(this.fixedArr.length>=5){
        this.$message.error(this.$t('最多置顶5个'));
        return
      }
      const target = this.selectOption.find(item => item.code === code)
      this.fixedArr.push(target)
      this.setSortOption()
    },
    unfixed(code) {
      const target = this.selectOption.find(item => item.code === code)
      this.sortOption.unshift(target)
      this.fixedArr = this.fixedArr.filter(i => i.code !== target.code)
    },
    submit () {
      const {fixedArr,sortOption}=this;
      const sortCodes = [...fixedArr.map(i => `${i.code}:fixedtrue`),...sortOption.map(i => i.code)]
      this.$emit('after-save', sortCodes)
      this.cancel()
    },
    cancel () {
      this.$emit('change', false)
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="scss" scoped>
.align-center {
  align-items: center;
}
.jc-between {
  justify-content: space-between
}
.select-view {
  width: 460px;
  height: 480px;
  margin-right: 10px;
}
.sort_view_outside {
  width: 350px;
  height: 480px;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  overflow: hidden;
}
.sort_view {
  width: 350px;
  height: 480px;
  overflow: hidden auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
.warning{
  color:#1377ff;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #EBF3FE;
  border-radius: 4px;
  padding-left:20px;
  margin-bottom:10px;
  box-sizing: border-box;
  div{
    padding-left:24px;
    &:first-child{
      padding-left: 0;
      display:inline-block;
    }
  }
  &:before{
    content:"\e7a3";
    font-family: element-icons!important;
    margin-right:8px;
    font-size: 16px;
    vertical-align: bottom;
  }
}
.sortItem {
  width: 100%;
  height: 32px;
  line-height: 32px;
  min-height: 32px;
  background: #F6F7FB;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  margin-bottom: 10px;
  cursor: move
}
.sortItem:last-child {
  margin-bottom: 0;
}
.sortIcon {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  cursor: pointer;
}
</style>
