var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          class: _vm.defaultClass,
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            "append-to-body": true,
            "show-close": _vm.showClose,
            "close-on-press-escape": false,
            width: _vm.width,
            "modal-append-to-body": _vm.modalAppendToBody,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("template", { slot: "title" }, [_vm._t("title")], 2),
      _vm._t("default"),
      _vm.showFooter
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }