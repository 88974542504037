var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-dialog",
    {
      attrs: { title: _vm.$t("title11006"), width: "800px" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "flex-row" }, [
        _c(
          "div",
          { staticClass: "select-view" },
          [
            _c(
              "el-checkbox",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { indeterminate: _vm.isIndeterminate },
                on: { change: _vm.handleCheckAllChange },
                model: {
                  value: _vm.checkAll,
                  callback: function ($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll",
                },
              },
              [_vm._v("全选")]
            ),
            _c(
              "div",
              { staticClass: "flex-col" },
              _vm._l(_vm.dataItems, function (checkbox, index) {
                return _c("div", { key: "checkbox" + index }, [
                  _c("div", { staticClass: "mb20" }, [
                    _vm._v(_vm._s(checkbox.label)),
                  ]),
                  checkbox.children && checkbox.children.length
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.selectArr,
                                callback: function ($$v) {
                                  _vm.selectArr = $$v
                                },
                                expression: "selectArr",
                              },
                            },
                            _vm._l(checkbox.children, function (item) {
                              return _c(
                                "el-col",
                                {
                                  key: item.code,
                                  staticStyle: {
                                    padding: "0",
                                    "margin-bottom": "20px",
                                  },
                                  attrs: { span: 8 },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        disabled: item.disabled,
                                        label: item.code,
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.selectItem(item, val)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.selectArr,
                                callback: function ($$v) {
                                  _vm.selectArr = $$v
                                },
                                expression: "selectArr",
                              },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    padding: "0",
                                    "margin-bottom": "20px",
                                  },
                                  attrs: { span: 8 },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        disabled: checkbox.disabled,
                                        label: checkbox.code,
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.selectItem(checkbox, val)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(checkbox.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _c("div", { staticClass: "sort_view_outside" }, [
          _c(
            "div",
            { ref: "sort_view", staticClass: "sort_view flex-col" },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "16px", color: "#999" } },
                [_vm._v(_vm._s(_vm.$t("title11008")))]
              ),
              _vm._l(_vm.fixedArr, function (item) {
                return _c(
                  "div",
                  {
                    key: "fixeditem_" + item.code,
                    staticClass: "sortItem flex-row jc-between",
                  },
                  [
                    _c("div", { staticClass: "flex-row align-center" }, [
                      _c("img", {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          margin: "0 10px",
                        },
                        attrs: { src: require("@/assets/images/sortIcon.png") },
                      }),
                      _c("span", [_vm._v(_vm._s(item.label))]),
                    ]),
                    !item.disabled
                      ? _c("div", { staticClass: "flex-row align-center" }, [
                          _c("img", {
                            staticClass: "sortIcon",
                            attrs: {
                              src: require("@/assets/images/sort_fixed.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.unfixed(item.code)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              _vm._l(_vm.sortOption, function (item, index) {
                return [
                  _c("div", { staticClass: "flex-row jc-between mb10" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                  _c(
                    "div",
                    {
                      key: "sort_view" + index + _vm.viewKeys[index],
                      class: "sort_view" + index,
                    },
                    [
                      _vm._l(item.children, function (child, j) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "sortItem flex-row jc-between draggable",
                              class: "draggable" + index,
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-row align-center" },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                      margin: "0 10px",
                                    },
                                    attrs: {
                                      src: require("@/assets/images/sortIcon.png"),
                                    },
                                  }),
                                  _c("span", [_vm._v(_vm._s(child.label))]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex-row align-center" },
                                [
                                  !child.disabled
                                    ? _c("img", {
                                        staticClass: "sortIcon",
                                        attrs: {
                                          src: require("@/assets/images/sort_delete.png"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(child.code)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v(_vm._s(_vm.$t("title587")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("title1013")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }