var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-scroll",
    {
      ref: "virtualScroll",
      style: { height: _vm.height },
      attrs: { data: _vm.list, "key-prop": _vm.rowKey, dynamic: true },
      on: { change: _vm.virtualChange },
    },
    [
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: "tableKey" + _vm.tableKey,
              staticClass: "white-bg radius-table noborder-resize",
              attrs: {
                border: "",
                stripe: false,
                data: _vm.tableData,
                "row-key": _vm.rowKey,
                height: "100%",
                "default-sort": _vm.defaultSort,
              },
              on: {
                "header-dragend": _vm.headerDragend,
                "header-click": _vm.headerClick,
                "sort-change": _vm.handleSort,
              },
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(_vm.defaultCol, function (column, index) {
            return [
              _vm.isVScroll && column.type && column.type.includes("selection")
                ? _c("v-column", {
                    key: index + "v",
                    attrs: { resizable: false, type: "selection", width: "40" },
                  })
                : _vm._e(),
              !_vm.isVScroll && column.type && column.type.includes("selection")
                ? _c("el-table-column", {
                    key: index + "v",
                    attrs: { resizable: false, type: "selection", width: "40" },
                  })
                : _vm._e(),
              _c(
                "el-table-column",
                _vm._b(
                  {
                    key: index,
                    attrs: {
                      "class-name": column.className,
                      "label-class-name": column.labelClassName || "",
                      resizable: !!column.labelClassName,
                      prop: column.prop,
                      label: column.label,
                      width: column.width,
                      sortable: column.sortable,
                      fixed: column.isFixed,
                      align: column.align,
                      "min-width": column.minWidth,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              !column.render
                                ? [
                                    column.toFixed
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                Number(
                                                  scope.row[column.prop] ||
                                                    scope.row[column.prop] === 0
                                                    ? scope.row[column.prop]
                                                    : column.defaultValue || "0"
                                                ).toFixed(2)
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row[column.prop] ||
                                                  scope.row[column.prop] === 0
                                                  ? scope.row[column.prop]
                                                  : column.defaultValue || "-"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                : [
                                    _c("RenderDom", {
                                      attrs: {
                                        row: scope.row,
                                        "row-index": scope.$index,
                                        index: index,
                                        render: column.render,
                                        column: column,
                                      },
                                    }),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  "el-table-column",
                  _vm.$attrs,
                  false
                ),
                [
                  column.content
                    ? _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("span", [_vm._v(_vm._s(column.label))]),
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c("div", {
                              attrs: { slot: "content" },
                              domProps: { innerHTML: _vm._s(column.content) },
                              slot: "content",
                            }),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }