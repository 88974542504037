import request from '@/utils/request'
import { praseStrEmpty, forChatplus } from "@/utils/ruoyi";

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/page',
    method: 'get',
    params: query
  })
}

// 获取用户精简信息列表
export function listSimpleUsers() {
  return request({
    url: '/system/user/list-all-simple',
    method: 'get'
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/system/user/get?id=' + praseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/system/user/create',
    method: 'post',
    data: data
  })
}
// 新增子账户
export function createSubAccount(data) {
  return request({
    url: '/erp/user-shop/createSubAccount',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  let url = forChatplus() ? '/erp/user/update' : '/system/user/update'
  return request({
    url: url,
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/erp/user/delete?id=' + userId,
    method: 'delete'
  })
}

// 导出用户
export function exportUser(query) {
  return request({
    url: '/system/user/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

// 验证旧手机号
export function checkOldMobile(data) {
  return request({
    url: '/system/user/profile/check-old-mobile',
    method: 'post',
    data,
  })
}
// 修改手机号
export function updateMobile(data) {
  return request({
    url: '/system/user/profile/update-mobile',
    method: 'post',
    data,
  })
}

// 用户密码重置
export function resetUserPwd(id, password) {
  const data = {
    id,
    password
  }
  return request({
    url: '/system/user/update-password',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(id, status) {
  const data = {
    id,
    status
  }
  let url = forChatplus() ? '/erp/user/update-status' : '/system/user/update-status'
  return request({
    url: url,
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile/get',
    method: 'get'
  })
}
// 获取销售地区
export function getLocalRegion() {
  return request({
    url: '/erp/tenant/get-local-region',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile/update',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(data) {
  return request({
    url: '/system/user/profile/update-password',
    method: 'put',
    data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/update-avatar',
    method: 'put',
    data: data
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: '/system/user/get-import-template',
    method: 'get',
    responseType: 'blob'
  })
}
// 获得新手指引
export function noviceGuide() {
  return request({
    url: '/erp/novice-guide/get',
    method: 'get'
  })
}

//完成阅读
export function guideFinishRead(data) {
  return request({
    url: '/erp/novice-guide/finishRead',
    method: 'post',
    data
  })
}
//不再提示
export function guideNeverShow(data) {
  return request({
    url: '/erp/novice-guide/neverShow',
    method: 'post',
    data
  })
}

//设置当前销售额取值方式
export function setSaleAmountPolicy(policy) {
  return request({
    url: '/erp/shop-order/set-sale-amount-policy?policy='+policy,
    method: 'post',
  })
}
//获取当前销售额取值方式
export function getSaleAmountPolicy() {
  return request({
    url: '/erp/shop-order/get-sale-amount-policy',
    method: 'get',
  })
}
//获得打包费分页
export function packingChargesPage(params) {
  return request({
    url: '/erp/packing-charges/page',
    method: 'get',
    params
  })
}
//创建打包费
export function packingChargesCreate(data) {
  return request({
    url: '/erp/packing-charges/create',
    method: 'POST',
    data
  })
}
//更新打包费
export function packingChargesUpdate(data) {
  return request({
    url: '/erp/packing-charges/update',
    method: 'put',
    data
  })
}
//删除打包费
export function packingChargesDelete(params) {
  return request({
    url: '/erp/packing-charges/delete',
    method: 'delete',
    params
  })
}
//批量删除打包费
export function packingChargesBatchDelete(params) {
  return request({
    url: '/erp/packing-charges/batch-delete',
    method: 'delete',
    params
  })
}

