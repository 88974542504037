<template>
  <div class="control-row flex-row" ref="tableControlBox" :style="{'height': height}">
    <div style="color: #999;margin-left: 10px">
      <span v-if="total">{{`${$t('title11002')}${total}${$t('title11003')}`}}</span>
      <slot name="tips">
        <span v-if="tips" style="margin-left: 20px">{{ tips }}</span>
      </slot>
      <slot></slot>
    </div>
    <div class="flex-row align-center">
      <slot name="checkCorner">
        <div class="flex-row align-center">
          <el-checkbox v-if="showCheckL" v-model="computedCheckL">{{checkLStr || $t('title11004')}}</el-checkbox>
          <el-checkbox v-if="showCheckR" v-model="computedCheckR">{{checkRStr || $t('title11005')}}</el-checkbox>
        </div>
        <el-tooltip class="item" effect="dark" placement="top" v-if="showCutCurrency">
          <div slot="content">温馨提示：<br/>仅支持单地区选择时切换币种，不支持多地区切换。</div>
          <el-button :disabled="isClickCurrency" plain style="color: #1377FF;" :class="['myCurrency1',isCutCurrency&&'rotate']" size="small" @click="btnClick('currency')">
            <i></i>
            <span>{{ $t('title1265') }}</span>
          </el-button>
        </el-tooltip>
        <div v-if="showCheckL || showCheckR ||showCutCurrency" class="verticalLine"></div>
      </slot>
      <div class="flex-row align-center">
        <missionCenter class="btn" v-if="!isHideTask" :isHideTask="isHideTask" :isInclude="isInclude" :isImport="isImport" ref="mission"></missionCenter>
        <el-tooltip class="item" effect="dark" :content="$t('title5')" placement="top-start">
          <div class="btn" v-if="!isHideHelp" @click="btnClick('help')">
            <img src="@/assets/images/control-help.png" />
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('title1897') + $t('title2379')" placement="top">
          <div class="btn" v-if="!isHideRefresh" @click="btnClick('refresh')">
            <img src="@/assets/images/control-refresh.png" />
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('title430') + $t('title10130') + $t('title10515')" placement="top">
          <div class="btn" v-if="!isHideSetting" @click="btnClick('setting')">
            <img src="@/assets/images/control-setting.png" />
          </div>
        </el-tooltip>
        <el-tooltip v-exportAuthButton class="item" effect="dark" :content="$t('title192') + $t('title2379')" placement="top-end">
          <div class="btn" v-if="!isHideDownload" @click="btnClick('download')">
            <img src="@/assets/images/control-download.png" />
          </div>
        </el-tooltip>
      </div>
    </div>
    <sortPanel v-if="sortPanelShow && sortType !== 'tree'" v-model="sortPanelShow" :sortItems="sortItems" @after-save="afterSave" />
    <sortTreePanel v-if="sortTreePanelShow && sortType === 'tree'" v-model="sortTreePanelShow" :sortItems="sortItems" @after-save="afterSave" />
  </div>
</template>
<script>
import sortPanel from '@/components/TableControl/sortPanel'
import sortTreePanel from '@/components/TableControl/sortTreePanel'
import missionCenter from '@/views/crm/components/missionCenter/index.vue'
import { debounce } from 'throttle-debounce'
export default {
  components: {
    sortPanel,
    sortTreePanel,
    missionCenter
  },
  props: {
    height: {
      type: String,
      default: '40px'
    },
    localLabel:String,//storage名称，保存排序
    total: [String, Number],
    tips: String,
    showCheckL: {
      type: Boolean,
      default: false
    },
    dataItems:{
      type:Array,
      default:[]
    },
    isInclude:{
      type:Boolean,
      default:true
    },
    isImport:{
      type:Boolean,
      default:true
    },
    isHideTask:{
      type:Boolean,
      default:true
    },
    isHideHelp:{
      type:Boolean,
      default:false
    },
    isHideRefresh:{
      type:Boolean,
      default:false
    },
    isHideSetting:{
      type:Boolean,
      default:false
    },
    isHideDownload:{
      type:Boolean,
      default:false
    },
    checkL: {
      type: Boolean,
      default: false
    },
    checkLStr: String,
    showCutCurrency: {
      type: Boolean,
      default: false
    },
    isClickCurrency:{
      type: Boolean,
      default: true
    },
    isCutCurrency: {
      type: Boolean,
      default: false
    },
    showCheckR: {
      type: Boolean,
      default: false
    },
    checkR: {
      type: Boolean,
      default: false
    },
    checkRStr: String,
    dataItems: {
      type: Array,
      default: () => []
    },
    sortType: null
  },
  data () {
    return {
      sortPanelShow: false,
      sortTreePanelShow: false
    }
  },
  mounted() {
    const container = this.$refs.tableControlBox
    const resizeObserver = new ResizeObserver(entries => {
      const dom = this.$refs.mission
      this.$store.state.missionCenter = dom
    })
    resizeObserver.observe(container)
  },
  beforeDestroy() {
    const container = this.$refs.tableControlBox
    const resizeObserver = new ResizeObserver(entries => {})
    resizeObserver.unobserve(container)
  },
  computed: {
    computedCheckL: {
      get: function() {
        return this.checkL
      },
      set: function(val) {
        this.$emit('update:checkL', val);
        this.$emit('refresh');
      }
    },
    computedCheckR: {
      get: function() {
        return this.checkR
      },
      set: function(val) {
        this.$emit('update:checkR', val);
        this.$emit('refresh');
      }
    },
    sortItems() {
      const arr = this.dataItems.map(i => {
        let children = []
        let target = {}
        if (i.children && i.children.length) {
          children = i.children.map(child => {
            return {
              ...child,
              checked: child.sort !== 99
            }
          })
        }
        target = {
          ...i,
          checked: i.sort !== 99
        }
        if (children.length) target.children = children
        return target
      })
      return arr
    }
  },
  methods: {
    btnClick: debounce(300, function(type) {
      if (type === 'setting') {
        this.sortPanelShow = true
        this.sortTreePanelShow = true
      } else {
        this.$emit(type)
      }
    }),
    afterSave(arr){
      this.$emit('afterSave',arr)
    }
  }
}
</script>
<style lang="scss" scoped>
.control-row {
  width: 100%;
  align-items: center;
  justify-content: space-between
}
.verticalLine {
  height: 16px;
  width: 1px;
  background: #e4e4e4;
  margin: 0 20px;
}
.align-center {
  align-items: center
}
.btns-row {
  align-items: center;
  justify-content: flex-end
}
.btn {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}
.btn:first-child {
  margin-left: 0px;
}
.btn:hover {
  background: #f2f2f2;
}
::v-deep .el-checkbox__input {
  margin-bottom: 1px
}
::v-deep .el-checkbox__label {
  font-size: 12px;
  color: #333
}
</style>
