<template>
  <!-- 虚拟滚动不适用斑马纹（stripe） -->
  <v-scroll
    ref="virtualScroll"
    :data="list"
    :key-prop="rowKey"
    :style="{'height': height}"
    :dynamic="true"
    @change="virtualChange">
    <el-table
      class="white-bg radius-table noborder-resize"
      border
      v-loading="loading"
      :stripe="false"
      :key="`tableKey${tableKey}`"
      :data="tableData"
      :row-key="rowKey"
      height="100%"
      v-bind="$attrs"
      v-on="$listeners"
      @header-dragend="headerDragend"
      @header-click="headerClick"
      :default-sort="defaultSort"
      @sort-change="handleSort">
      <template v-for="(column, index) in defaultCol">
        <v-column :resizable="false" :key="index+'v'" v-if="isVScroll && column.type && column.type.includes('selection')" type="selection" width="40" />
        <el-table-column :resizable="false" :key="index+'v'" v-if="!isVScroll && column.type && column.type.includes('selection')" type="selection" width="40" />
        <el-table-column
          :key="index"
          :class-name="column.className"
          :label-class-name="column.labelClassName||''"
          :resizable="!!column.labelClassName"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :sortable="column.sortable"
          :fixed="column.isFixed"
          :align="column.align"
          :min-width="column.minWidth"
          v-bind="$attrs"
        >
          <template v-if="column.content" slot="header">
            <span>{{ column.label }}</span>
            <el-tooltip placement="top">
              <div slot="content" v-html="column.content"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <template v-if="!column.render">
              <span v-if="column.toFixed">
                {{Number(scope.row[column.prop] || scope.row[column.prop] === 0 ? scope.row[column.prop] : (column.defaultValue || '0')).toFixed(2)}}
              </span>
              <span v-else>
                {{scope.row[column.prop] || scope.row[column.prop] === 0 ? scope.row[column.prop] : (column.defaultValue || '-')}}
              </span>
            </template>

            <!-- render -->
            <template v-else>
              <RenderDom :row="scope.row" :row-index="scope.$index" :index="index" :render="column.render" :column="column" />
            </template>
            <!-- slot 你可以其他常用项 -->
          </template>
        </el-table-column>
      </template>
    </el-table>
  </v-scroll>
</template>
<script>
import tableHeaderDragend from '@/mixins/tableHeaderDragend'
import tableEcharts from '@/views/erp/goodsAnalysis/components/charts.vue'
import lineEcharts from '@/views/bi/financialData/goodsProfit/components/goodsAnalysis/charts.vue'
export default {
  props: {
    // 是否虚拟滚动 （虚拟滚动使用专门选择器）
    isVScroll: {
      type: Boolean,
      default: true
    },
    // 使用虚拟滚动必须传
    rowKey: {
      type: String,
      default: 'id'
    },
    loading: Boolean,
    tableKey: [String, Number],
    list: {
      type: Array,
      default: () => []
    },
    defaultCol: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ({})
    },
    height: {
      type:String,
      default:''
    }
  },
  mixins: [tableHeaderDragend],
  components: {
    tableEcharts,
    lineEcharts,
    RenderDom: {
      name: 'renderTable',
      functional: true, // 函数式组件 - 无 data 和 this 上下文 => better render
      props: {
        row: Object, // 单行数据
        index: Number, // 列的index
        rowIndex: Number,
        render: Function,
        column: {
          type: Object,
          default: null
        }
      },
      /**
       * @param {Function} createElement - 原生创建dom元素的方法， 弃用，推荐使用 jsx
       * @param {Object} ctx - 渲染的节点的this对象
       * @argument 传递参数 row index
       */
      render (createElement, ctx) {
        const { row, rowIndex, index } = ctx.props
        return ctx.props.render(createElement, row, index, rowIndex)
      }
    }
  },
  data () {
    return {
      tableData: []
    }
  },
  created() {},
  methods: {
    handleSort(sortParams) {
      if (!(this.defaultSort && this.defaultSort.prop)) return
      this.$emit('handleSort', sortParams)
    },
    virtualChange(renderData) {
      this.tableData = renderData
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
