<template>
  <div class="sidebar-logo-container" @click="toggleNav">
    <transition name="sidebarLogoFade">
      <div v-if="true" key="collapse" class="sidebar-logo-link">
        <img :src="finallyLogo" class="sidebar-logo" />
        <!-- <h1 v-else class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }} </h1> -->
      </div>
      <div v-else key="expand" class="sidebar-logo-link">
        <img v-if="logo" :class="[type==='nav'&&'small']" :src="type==='nav'?logo:logo1" class="sidebar-logo" />
        <!-- <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }} </h1> -->
      </div>
    </transition>
  </div>
</template>

<script>
import { forChatplus } from '../../../utils/ruoyi'
import variables from '@/assets/styles/variables.scss'
export default {
  name: 'SidebarLogo',
  props: {
    type:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      title: `${this.$t('title10299')}ERP`,
      logo: require('@/assets/logo/logo.svg'),
      chatPlusLogo: require('@/assets/chatPlus/chartsPlus_logo.svg')
    }
  },
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme
    },
    style(){
      if(this.type==='nav'){
        return {
          width:'86px',
          height:'41px'
        }
      }
      return {}
    },
    finallyLogo() {
      const url = this.forChatplus() ? this.chatPlusLogo : this.logo
      return url
    }
  },
  
  methods:{
    forChatplus,
    toggleNav(){
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  text-align: center;
  & .sidebar-logo-link {
    cursor: pointer;
    height: 100%;
    width: 100%;
    .sidebar-logo {
      width: 42px;
      height: 42px;
      vertical-align: middle;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      vertical-align: middle;
    }
  }
}
</style>
