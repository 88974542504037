<template>
  <div id="app">
    <router-view />
    <my-help v-if="showHelp.show" v-model="showHelp.show" :info="showHelp.value" />
  </div>
</template>

<script>
import { notifyImport, notifyInclude } from '@/utils/notify.js'
import myHelp from '@/components/MyHelp'
import {checkUpdate,getVersion} from './auto-update'
import {MessageBox} from 'element-ui'
import { mapGetters, mapActions } from 'vuex'
import { getAccessToken, getTenantId } from '@/utils/auth'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { forChatplus } from './utils/ruoyi'
export default  {
  name:  'App',
  components: {
    myHelp
  },
  computed: {
    ...mapGetters(['showHelp'])
  },
  data() {
    return {
      dataPlusIcon: require('./assets/icons/svg/dataPlus.ico')
    }
  },
  created() {
    let favicon = document.querySelector('link[rel="icon"]');
    if (!forChatplus()) return
    if (favicon !== null) {  
      favicon.href = this.dataPlusIcon;
    } else {  
      favicon = document.createElement("link");
      favicon.rel = "icon";
      favicon.href = this.dataPlusIcon;
      document.head.appendChild(favicon);
    }
  },
  mounted() {
    this.checkUp()
    window.addEventListener('mousewheel',()=>{
        // 找到浮层元素并移出
        const vcTooltipDom = document.getElementById('previewImage');
        vcTooltipDom && document.body.removeChild(vcTooltipDom);
    });
    // this.initSource()
  },
  metaInfo() {
    const app_title = forChatplus() ? '乐聊' : process.env.VUE_APP_TITLE
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
        return title ? `${title} - ${app_title}` : app_title
      }
    }
  },
  methods:{
    async checkUp(){
      if(await checkUpdate()&&process.env.ENV==='production'){
        MessageBox.confirm(this.$t('发现新版本，点击更新完成升级。体验新版本'),this.$t('系统消息'), {
          dangerouslyUseHTMLString: true,
          showCancelButton:false,
          customClass:'update-message-box',
          confirmButtonText:this.$t('title2102'),
          showClose:false,
          closeOnClickModal:false,
          closeOnPressEscape:false,
          // type: "warning"
        }).then(()=>{
          location.reload(true)
        }).catch(()=>{
        })
        localStorage.setItem('version',(await getVersion()).version)
      }
    },
    initSource () {
      const newSource = new EventSourcePolyfill(`${process.env.VUE_APP_BASE_API}/admin-api/crm/message-push/connect/${getTenantId()}`, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          'Cache-Control': 'no-cache',
          'Tenant-Id': getTenantId()
        },
        hearbeatTimeout: 10 * 60 * 1000
      })
      newSource.onopen = (e) => {
        console.log('open')
      }
      newSource.onmessage = async (e) => {
        const info = JSON.parse(e.data)
        console.log(info)
        let type = info.type
        if(type == 'kol_task'){
          notifyInclude(this,JSON.parse(e.data))
        }else{
          notifyImport(this,JSON.parse(e.data))
        }
      }
      newSource.onerror = (e) => {
        console.log('error', e)
      }
    },
  }
}
</script>
<style lang="scss">
.my-notify{
  padding: 0 !important;
  width: auto !important;
  box-shadow: 2px 2px 10px 0px rgba(176,176,176,0.5);
  border-radius: 10px;
  .el-notification__group{
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
