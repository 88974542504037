<template>
  <div>
    <myDialog v-model="visible" width="860px" title=" " modal :modalAppendToBody="false">
    <div class="flex-col" style="align-items: center; font-size: 14px">
      <span style="font-weight: 600; color: #333;margin-bottom: 10px;">{{ title }}</span>
      <span style="font-weight: 600; color: #999;margin-bottom: 20px;">{{ $t('title10174') }}</span>
      <div class="flex-row">
        <div class="imgView flex-col" style="align-items: center;margin-right: 20px">
          <img :src="banner1" />
          <span class="btn">{{ $t('title10175') }} ></span>
        </div>
        <div class="imgView flex-col" style="align-items: center;">
          <img :src="banner2" />
          <span class="btn" @click="open">{{ $t('title10176') }} ></span>
        </div>
      </div>
    </div>
    <template slot="footer">
      <el-button size="small" type="primary" @click="submit">{{ $t('title10177') }}</el-button>
    </template>
  </myDialog>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      banner1: require('@/assets/images/help1.png'),
      banner2: require('@/assets/images/help2.png')
    }
  },
  computed: {
    title () {
      return JSON.parse(this.info).title
    },
    url () {
      return JSON.parse(this.info).helpUrl
    }
  },
  methods: {
    open () {
      if (this.forChatplus()) {
        this.$message.success('帮助系统维护中')
        return
      }
      const base_url = 'https://erp.xinjianerp.com/docs/#'
      window.open(`${base_url}${this.url}`, '_blank')
    },
    submit () {
      this.$router.push('/system/user-info?state=2')
      this.$store.commit('setShopHelp', {})
      this.cancel()
    },
    cancel () {
      this.$emit('change', false)
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="scss" scoped>
.imgView {
  width: 400px;
  height: 318px;
  background: linear-gradient(180deg, #E8F3FF 0%, #D5E9FF 29%, #E6F2FE 57%, #FFFFFF 100%);
  border-radius: 6px;
  opacity: 0.9;
  .btn {
    color: #1377ff;
    margin-top: 24px;
    font-size: 12px;
    cursor: pointer;
  }
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
