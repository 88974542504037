<template>
  <el-dialog
    :class="defaultClass"
    :title="title" 
    :visible.sync="visible"
    :append-to-body="true"
    :show-close="showClose"
    :close-on-press-escape="false"
    :width="width"
    v-bind="$attrs"
    v-on="$listeners"
    :modal-append-to-body="modalAppendToBody"
  >
    <template slot="title">
      <slot name="title">
      </slot>
    </template>
    <slot>
    </slot>
    <div v-if="showFooter" slot="footer">
      <slot name="footer">
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'MyDialog',
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '1000px'
    },
    modal:{
      type: Boolean,
      default: false
    },
    defaultClass: {
      type: String,
      default: 'myElDialog'
    },
    showFooter: {
      type: Boolean,
      default: true
    },
  },
  mounted () {
    if(this.modal) {
      document.querySelector('#app .sidebar-container').style="z-index: 3000"
    }else {
      document.querySelector('#app .sidebar-container').style="z-index: 2000"
    }
  },
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.myElDialog .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0px !important;
}
</style>
